<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "reservation.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="800px" persistent v-if="reservation">
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs6 sm3 md3 lg3>
                    <v-text-field 
                      ref="guestName"
                      v-model="reservation.guestName" 
                      :label="$t('reservation.table_guest')"
                      :rules="guestNameRules"
                      :disabled="formReadOnly"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm3 md3 lg3>
                    <v-text-field
                      ref="memberCount"
                      v-model="reservation.memberCount"
                      :label="$t('reservation.table_member_count')"
                      type="number"
                      :rules="memberCountRules"
                      :disabled="formReadOnly"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      v-model="reservation.reservationCode"
                      :label="$t('reservation.table_reservation_code')"
                      :disabled="formReadOnly"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field 
                      ref="phoneNo"
                      v-model="reservation.phoneNo"
                      :label="$t('reservation.table_phone_no')"
                      :rules="phoneNoRules"
                      :disabled="formReadOnly"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      v-model="reservation.email"
                      :label="$t('reservation.table_email')"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6 lg6>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="menuCheckInDate"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reservation.checkInDate"
                          :label="$t('reservation.table_checkin_date')"
                          persistent-hint
                          prepend-icon="event"
                          :disabled="formReadOnly"
                          :rules="checkInDateRules"
                          required
                          v-bind="attrs"
                          v-on="on"                 
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reservation.checkInDate"
                        no-title
                        @input="menuCheckInDate = false"
                        :disabled="formReadOnly"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="menuCheckOutDate"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reservation.checkOutDate"
                          :label="$t('reservation.table_checkout_date')"
                          persistent-hint
                          prepend-icon="event"
                          :disabled="formReadOnly"
                          :rules="checkOutDateRules"
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reservation.checkOutDate"
                        no-title
                        @input="menuCheckOutDate = false"
                        :disabled="formReadOnly"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 sm12 md12 lg12>
                    <v-data-table
                      v-if="reservation.smartKeyItems && reservation.smartKeyItems.length > 0"
                      v-model="reservation.smartKeyItems"
                      :headers="headersSmartKeyItems"
                      :items="reservation.smartKeyItems"
                      class="elevation-1"
                      hide-default-footer
                    > 
                      <template v-slot:item.keyInfo="{ index, item }">
                        <v-text-field v-model="reservation.smartKeyItems[index].keyInfo" :hide-details="true" dense single-line :autofocus="true" :disabled="formReadOnly"></v-text-field>
                      </template>
                    </v-data-table>
                  </v-flex>
                
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green lighten-1" text dark @click="close">Cancel</v-btn>
              <v-btn color="green lighten-1" text dark @click="save">Save</v-btn>
              <v-btn color="green lighten-1" text dark :disabled="editedIndex < 0" @click="remove">Remove</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogUpload" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('reservation.btn_import') }}</span>
          </v-card-title>

          <v-card-text>
            <file-upload extensions="csv" contentType="text/csv" title="Drop Airbnb CSV for Reservations" @filePicked="onFilePick" name="csv-uploader"></file-upload>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>
    
    <v-toolbar flat color="white">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Reservation Actions
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="newItem">
            <v-list-item-icon>
              <v-icon color="primary">add_box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reservation.btn_new') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="newImport">
            <v-list-item-icon>
              <v-icon color="primary">library_add</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reservation.btn_import') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>         
          <v-list-item>
            <v-switch
              v-model="showDeploymentInfo"
              :label="$t('reservation.switch_deploy')"
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('reservation.table_search')"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="reservations"
      :search="search"
      class="elevation-1"
      item-key="reservationCode"
    >

      <template v-slot:item.edit="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="editItem(item)"
        >
          edit
        </v-icon>
      </template>

      
      <template v-slot:item.lastRequestOn="{ item }" >
        <v-tooltip right v-if="item.rejectReason">
          <template v-slot:activator="{ on }">
            <v-chip 
              v-on="on"
              v-if="item.lastRequestOn"
              :color="getColor(item.lastRequestOn, item.lastResponse, item.rejectReason)"
              dark
            >
              {{ item.lastRequestOn }}
            </v-chip>
          </template>          
          <span>{{ item.rejectReason }}</span>
        </v-tooltip>

        <v-chip 
          v-if="!item.rejectReason && item.lastRequestOn"
          :color="getColor(item.lastRequestOn, item.lastResponse, item.rejectReason)"
          dark
        >
          {{ item.lastRequestOn }}
        </v-chip>        
      </template>

      <template v-slot:item.isIdVerified="{ item }">
        <v-icon
          large
          class="mr-2"
          color="success"
          v-if="item.isIdVerified"
          @click="gotoMembers(item)"
        >
          people
        </v-icon>
        <v-icon
          large
          class="mr-2"
          color="warning"
          v-if="!item.isIdVerified"
          @click="gotoMembers(item)"
        >
          people
        </v-icon>
      </template>

      <template v-slot:item.deployToScanner="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="deployToScanner(item)"
        >
          video_camera_front
        </v-icon>
      </template>

      <template v-slot:item.removeFromScanner="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="removeFromScanner(item)"
        >
          videocam_off
        </v-icon>
      </template>

      <template v-slot:item.isKeyNotified="{ item }">
        <v-icon
          large
          class="mr-2"
          color="success"
          v-if="item.isKeyNotified"
        >
          notification_important
        </v-icon>
        <v-icon
          large
          class="mr-2"
          color="warning"
          v-if="!item.isKeyNotified"
        >
          notifications_paused
        </v-icon>
      </template>

    </v-data-table>
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 20px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'
import * as moment from 'moment'


const FileUpload = () => import('@/components/FileUpload.vue')

export default {
  data: () => ({
    showDeploymentInfo: false,
    // showMemberInfo: true,
    // showGuestInfo: true,
    // showReservationInfo: false,
    passwordTypes: ['temp', 'offline'],
    // useSmartlock: true,
    passwordType: 2,
    // lockDeviceIds: [],
    keypadDeviceId: '',
    menuCheckInDate: false,
    menuCheckOutDate: false,
    search: '',
    dialogUpload: false,
    dialog: false,
    effectiveTime: '14:00',
    invalidTime: '11:00',
    editedIndex: -1,
    defaultItem: {
      reservationCode: '',
      guestName: '',
      bookingId: '',
      phoneNo: '',
      email: '',
      memberCount: 1,
      checkInDate: '',
      checkOutDate: '',
      smartKeyItems: []
    },
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 25,
      sortBy: 'registeredOn',
      totalItems: 0,
      rowsPerPageItems: [10, 25, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]
    }
  }),
  components: {
    FileUpload,
  },
  computed: {
    formReadOnly () {
      if (this.editedIndex === -1) {
        return false;
      } else {
        if (this.reservation.referrer) {
          return true;
        } else {
          return false;
        }
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? this.$t('reservation.btn_new') : this.$t('reservation.btn_edit')
    },
    headers () {
      const defaultHearders = [
        { text: this.$t('reservation.table_edit'), value: 'edit', align: 'left', sortable: false },
        { text: this.$t('reservation.table_checkin_date'), value: 'checkInDate', align: 'left', sortable: true },
        { text: this.$t('reservation.table_checkout_date'), value: 'checkOutDate', align: 'left', sortable: true },
      ]

      defaultHearders.push(
        { text: this.$t('reservation.table_guest'), value: 'guestName', align: 'left', sortable: false},
        { text: this.$t('reservation.table_phone_no'), value: 'phoneNo', align: 'left', sortable: false},
        { text: this.$t('reservation.table_email'), value: 'email', align: 'left', sortable: false},
      )

      defaultHearders.push(
        // { text: this.$t('reservation.table_key_info'), value: 'keyInfo', align: 'left', sortable: false },
        { text: this.$t('reservation.table_reservation_code'), value: 'reservationCode', align: 'left', sortable: false},
        // { text: this.$t('reservation.table_booking_id'), value: 'bookingId', align: 'left', sortable: false},
        { text: this.$t('reservation.table_referrer'), value: 'referrer', align: 'left', sortable: false},
      )

      defaultHearders.push(
        { text: this.$t('reservation.table_member_count'), value: 'memberCount', align: 'left', sortable: false },
        // { text: this.$t('reservation.table_goto_members'), value: 'gotoMembers', align: 'left', sortable: false },
        { text: this.$t('reservation.table_goto_members'), value: 'isIdVerified', align: 'left', sortable: false },
        { text: this.$t('reservation.table_key_notified'), value: 'isKeyNotified', align: 'left', sortable: false }
      )

      if (this.showDeploymentInfo) {
        defaultHearders.push(
          { text: this.$t('reservation.table_deploy'), value: 'deployToScanner', align: 'left', sortable: false },
          { text: this.$t('reservation.table_last_request'), value: 'lastRequestOn', align: 'left', sortable: false },
          { text: this.$t('reservation.table_remove'), value: 'removeFromScanner', align: 'left', sortable: false }
        )
      }

      return defaultHearders

    },
    headersSmartKeyItems () {
      return [
        { text: this.$t('reservation.table_room_code'), value: 'roomCode', align: 'left', sortable: true },
        { text: this.$t('reservation.table_key_info'), value: 'keyInfo', align: 'left', sortable: true},
      ]
    },
    disabled () {
      return this.editedIndex === -1 ? false : true
    },
    guestNameRules () {
      return [(guestName) => !!guestName || this.$t('reservation.msg_guest_name')]
    },
    phoneNoRules () {
      return [(phoneNo) => !!phoneNo || this.$t('reservation.msg_phone_no')]
    },
    memberCountRules () {
      return [(memberCount) => {
        if (memberCount < 1 || memberCount > 12) {
          return this.$t('reservation.msg_member_count')
        } else {
          return true
        }
      }]
    },
    checkInDateRules () {
      return [(checkInDate) => !!checkInDate || this.$t('reservation.msg_checkin_date')]
    },
    checkOutDateRules () {
      return [(checkOutDate) => !!checkOutDate || this.$t('reservation.msg_checkout_date')]
    },
    ...mapState({
      reservations: state => state.host.reservations,
      reservation: state => state.host.reservation
    }),
  },

  async beforeRouteEnter (to, from, next) {

    AppSyncSubscriber.disconnect('onUpdateReservation')

    AppSyncSubscriber.disconnect('onRemoveReservation')

    AppSyncSubscriber.connect('host', 'onUpdateReservation', null, 'refreshReservations')

    AppSyncSubscriber.connect('host', 'onRemoveReservation', null, 'postRemoveReservations')

    const error = await store.dispatch('host/loadReservations', [store.state.host.listing]).catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }


  },
  methods: {
    async onFilePick(file) {
      // const error = await this.uploadJson({ fileObject: file.file }).catch(error => {
      //   console.log('uploadJson', error)
      //   return error
      // })

      const error = await this.uploadCsv(file).catch(error => {
        console.log('uploadCsv', error)
        return error
      })

      if (error) {
        CallDialog(error)
      }
    },
    newImport () {   
      this.dialogUpload = true
    },
    newItem () {
      this.editedIndex = -1;
      this.pickReservation(Object.assign({}, this.defaultItem))
      this.dialog = true
    },
    // editItem () {   
    //   console.log('editItem', this.selectedItems)
    //   this.editedIndex = this.reservations.indexOf(this.selectedItems[0])
    //   this.reservation = Object.assign({}, this.selectedItems[0])
    //   this.dialog = true
    // },
    editItem (item) {   
      // this.editedIndex = this.reservations.indexOf(item)
      // this.reservation = Object.assign({}, item)
      // this.dialog = true

      this.editedIndex = this.reservations.indexOf(item)
      this.pickReservation(Object.assign({}, item))
      this.dialog = true
    },
    closeUpload () {
      this.dialogUpload = false
    },
    async remove () {
      
      console.log('this.reservations[this.editedIndex]', this.reservations[this.editedIndex])

      // if (this.reservations[this.editedIndex].lastRequestOn) {
      //   CallDialog({message: "Remove deployment first!"})
      //   return
      // }

      const error = await this.removeReservation(this.reservations[this.editedIndex]).catch(e => {
        console.error('removeReservation', e)
        return e
      })

      if (error) {
        CallDialog(error)  
      } else {
        this.close()
      }
    },
    close () {
      this.editedIndex = -1
      this.pickReservation()
      this.dialog = false
      this.$refs.form.resetValidation()
    },
    async save () {
      console.log('this.editedIndex', this.editedIndex)
      console.log('this.$refs', this.$refs)

      const valid = this.$refs.form.validate()

      console.log('valid', valid)

      if (!valid) {
        return
      }

      console.log('this.reservation', this.reservation)

      if (this.reservation.checkInDate >= this.reservation.checkOutDate) {
        CallDialog({message: "Incorrect dates!"})
        return
      }

      let calendarParams;
      if (this.editedIndex > -1) {
        calendarParams = {
          listingId: this.reservation.listingId,
          checkInDate: this.reservation.checkInDate,
          checkOutDate: this.reservation.checkOutDate,
          oldCheckInDate: this.reservations[this.editedIndex].checkInDate,
          oldCheckOutDate: this.reservations[this.editedIndex].checkOutDate
        }
      } else {
        calendarParams = {
          listingId: this.reservation.listingId,
          checkInDate: this.reservation.checkInDate,
          checkOutDate: this.reservation.checkOutDate
        }
      }

      const calendars = await this.validateCalendar({
        params: calendarParams
      }).catch(e => {
        console.error('validateCalendar', e)
        return e
      })

      console.log('calendars', calendars)

      if (calendars.length > 0) {
        CallDialog({message: "Duplicated dates!"})
        return
      }

      if (this.editedIndex > -1) {
        this.reservation.bookingStatus = 'Changed'
      } else {
        this.reservation.bookingStatus = 'Confirmed'
      }

      const error = await this.generateReservation(this.reservation).catch(e => {
        console.error('generateReservation', e)
        return e
      })

      // const error = await this.updateReservationStatus(this.reservation).catch(e => {
      //   console.error('updateReservationStatus', e)
      //   return e
      // })

      if (error) {
        CallDialog(error)  
      } else {
        this.close()
      }      

      this.$refs.form.resetValidation()
      
    },
    getColor (lastRequestOn, lastResponse, rejectReason) {
      if (rejectReason) {
        return 'red'
      } else {
        if (lastRequestOn == lastResponse) {
          return 'green'
        } else {
          return 'orange'
        }
      }

    },
    async deployToScanner(pickedReservation) {

      try {

        this.pickReservation(pickedReservation)

        await this.validateScanners(pickedReservation.propertyCode)

        await this.validateFaceImgs()

        const deployResult = await this.dispatchScannerDeployment({
          reservationCode: pickedReservation.reservationCode, 
          listingId: pickedReservation.listingId,
          propertyCode: pickedReservation.propertyCode
        })
        
        deployResult.lastUpdateOn = pickedReservation.lastUpdateOn
        deployResult.rejectReason = ''

        await this.updateReservationStatus(deployResult)

      } catch(err) {
        console.error('dispatchScannerDeployment', err)
        CallDialog(err)
        throw err
      }

    },
    async removeFromScanner(pickedReservation) {
      try {
        const cancelResult = await this.resetScannerDeployment({
          reservationCode: pickedReservation.reservationCode, 
          listingId: pickedReservation.listingId
        })

        cancelResult.lastUpdateOn = pickedReservation.lastUpdateOn
        cancelResult.rejectReason = ''

        await this.updateReservationStatus(cancelResult)

      } catch(err) {
        console.error('dispatchScannerDeployment', err)
        CallDialog(err)
        throw err
      }
    },
    ...mapActions({
      loadReservations: 'host/loadReservations',
      gotoMembers: 'host/gotoMembers',
      pickReservation: 'host/pickReservation',
      updateReservationStatus: 'host/updateReservationStatus',
      generateReservation: 'host/generateReservation',
      removeReservation: 'host/removeReservation',
      uploadJson: 'host/uploadJson',
      uploadCsv: 'host/uploadCsv',
      validateCalendar: 'host/validateCalendar',
      validateFaceImgs: 'host/validateFaceImgs',
      validateScanners: 'scanner/validateScanners',
      dispatchScannerDeployment: 'scanner/dispatchScannerDeployment',
      resetScannerDeployment: 'scanner/resetScannerDeployment',
    })
  }
}
</script>